import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/cuizizine/cuizizine/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const StyledParagraph = makeShortcode("StyledParagraph");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Image alt="About" src="/images/about.jpg" position="right" mdxType="Image" />
    <h1>{`À propos`}</h1>
    <StyledParagraph mdxType="StyledParagraph">Un site de recettes principalement végétaliennes pour égayer vos papilles.</StyledParagraph>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      